var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("table", { staticClass: "tbl_row mt10" }, [
      _vm._m(0),
      _c(
        "tbody",
        [
          _vm._l(_vm.closeInfoListReal2, function (vo, idx) {
            return _c("tr", { key: idx }, [
              _c("th", [_vm._v(_vm._s(vo.aCdNm))]),
              _c("td", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: vo.bVal === undefined,
                    expression: "vo.bVal === undefined",
                  },
                ],
                attrs: { colspan: "3" },
                domProps: { innerHTML: _vm._s(vo.aVal) },
              }),
              _c("td", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: vo.bVal !== undefined,
                    expression: "vo.bVal !== undefined",
                  },
                ],
                domProps: { innerHTML: _vm._s(vo.aVal) },
              }),
              _c(
                "th",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: vo.bVal !== undefined,
                      expression: "vo.bVal !== undefined",
                    },
                  ],
                },
                [_vm._v(_vm._s(vo.bCdNm))]
              ),
              _c("td", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: vo.bVal !== undefined,
                    expression: "vo.bVal !== undefined",
                  },
                ],
                domProps: { innerHTML: _vm._s(vo.bVal) },
              }),
            ])
          }),
          _vm.parentInfo &&
          _vm.parentInfo.menuCd === "02" &&
          ["KR", "JP"].indexOf(_vm.parentInfo.polCtrCd) > -1
            ? _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P030.020")))]),
                _vm.parentInfo.polShtmCd !== "02"
                  ? _c("td", { attrs: { colspan: "3" } }, [
                      _vm._v(_vm._s(_vm.vesselDetail.cgoPic)),
                    ])
                  : _vm._e(),
                _vm.parentInfo.polShtmCd === "02"
                  ? _c(
                      "td",
                      { attrs: { colspan: "3" } },
                      [
                        _c("p", [
                          _vm._v(_vm._s(_vm.parentInfo.txtCfsPolTrmlInfo)),
                        ]),
                        _c("font", { attrs: { color: "red", id: "cfsred" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.CSBK100.087"))),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150" } }),
      _c("col"),
      _c("col", { attrs: { width: "150" } }),
      _c("col"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }